<template>
  <div id="LayHeader_zh" ref="aqthearder">
    <div
        class="return"
        @click="back"
        v-if="!['/index_infor','/index_infor_mn'].includes($route.path)">
      <i class="cubeic-back"></i>
    </div>
    <div class="header_title aqt_ce_ve" @dblclick="backTop">
      <p v-if="['/index_cn'].includes($route.path)" class="titlefont">{{ title.zh?title.zh:title.mn }}</p>
<!--      <img v-if="['/index_cn'].includes($route.path)" :src="headerimgH" alt style="height: 98%;"/>-->
    </div>
    <div
        class="header_search"
        v-if="['/index_cn'].includes($route.path) && showSearch">
      <i class="iconfont iconshaixuan" @click="isShowPopup=true"></i>
    </div>
    <book-search :isShowPopup="isShowPopup" @on-mask-click="isShowPopup=false"
                 @on-search-click="onSearchClick"></book-search>

<!--    <div class="header_mn" @click="goMn"-->
<!--         v-if="['/index_cn'].includes($route.path)">-->
<!--      <span class="aqt_lang_mn titlefont">{{'ᠮᠣᠩᠭᠣᠯ'|U2M}}</span>-->
<!--    </div>-->
  </div>
</template>

<script>
import {log} from "util";

export default {
  components: {
    BookSearch: (resolve) => {
      require(["@/components/BookSearchZh.vue"], resolve);
    },
  },
  data() {
    return {
      title:'',
      isShowPopup:false,
      showSearch: true,
      thisshow: true,
      condition: {
        searchtype: 1, //汉语列表搜索表示，不传默认是蒙语列表
        status: "1", //1书名、2isbn号、3作者、4出版社
        keyword: "", //关键词
        page: 0, //分页
        type: 2, //书籍类型和蒙语列表状态一样
        library_id: null, //馆藏id  48内蒙古财经大学图书馆
      },
      headerimgH:'http://localhost:35642/img/indexbanner.4836896d.png'
    };
  },
  methods: {

    goMn() {
      this.$router.push("/index_mn");
    },
    /*****************************************************************
     *
     * 数据处理
     *
     *****************************************************************/
    processData(data) {
      let ss = [];
      data.forEach((item, index) => {
        ss.push({
          id: item.book_id,
          title: item.name_lang.mn
              ? item.name_lang.mn
              : item.name_lang.zh,
          img: item.img,
          author: item.author_lang.mn
              ? item.author_lang.mn
              : item.author_lang.zh,
          // press:item.press_name_lang.mn?item.press_name_lang.mn:item.press_name_lang.zh,
        });
      });
      return ss;
    },
    back() {
      this.$router.go(-1);

    },
    backTop() {
      document.body.scrollTop = 0;
      document.documentElement.scrollTop = 0;
    },
    /*****************************************************************
     *
     * 点击搜索
     *
     *****************************************************************/
    onSearchClick(item) {
      let _this = this;
      if (_this.$route.path == "/zhebooklist") {
        item.type = 2;
      } else if (_this.$route.path == "/zhBookList") {
        item.type = 1;
      }
      item.library_id = _this.library_id //馆藏id  48内蒙古财经大学图书馆
      _this.$store.state.search = item;
      _this.isShowPopup = false
    },

    getsidebar_pic() {
      const _this = this;
      const id = this.$route.query.number ? this.$route.query.number : (JSON.parse(localStorage.getItem("theme_color")).library_id ? JSON.parse(localStorage.getItem("theme_color")).library_id : '')
      _this.$http.get('api/web/v1/h5/search?library_id=' + id)
          .then((val) => {
            console.log(val, 88)
            this.title=val.data.name_lang
          }).catch((err) => {
      })
    },
  },
  mounted() {
    this.$emit("on-hearload", this.$refs.aqthearder.offsetHeight);
    this.title = localStorage.getItem("theme_color") ? JSON.parse(localStorage.getItem("theme_color")).name : "";
    this.getsidebar_pic()
  },
  computed: {
    library_id() {
      let vv = JSON.parse(localStorage.getItem("theme_color")) ? JSON.parse(localStorage.getItem("theme_color")).library_id : null;
      return vv;
    },
  },
  // watch: {
  //   $route(newName, oldName) {
  //     this.title = localStorage.getItem("theme_color") ? JSON.parse(localStorage.getItem("theme_color")).name : "";
  //     console.log('this.title',this.title)
  //   },
  // },
};
</script>

<style lang="less">
#LayHeader_zh {
  width: 100%;
  height: 5rem;
  line-height: 5rem;
  background: var(--themeColor);
  display: flex;
  color: #ffffff;
  font-size: 2rem;
  text-align: center;
  // justify-content: space-between;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 2;

  .return {
    width: 8%;
    height: 100%;
  }

  .saoma {
    position: absolute;
    left: 1rem;
    width: 8%;
    height: 100%;

    i {
      font-size: 1.6rem;
    }
  }

  .header_title {
    width: 80%;
    height: 100%;
    white-space: nowrap;
    -o-text-overflow: ellipsis;
    text-overflow: ellipsis;
    overflow: hidden;
    margin: auto;
    z-index: 2;
    padding: 1rem 5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    p{
      font-size: 1.6rem;
    }
  }

  .header_mn {
    width: 12%;
    height: 100%;
    position: absolute;
    right: 0.5rem;
    z-index: 9;

    span {
      font-size: 16px;
      height: 100%;
      // margin-right: 1rem;
    }
  }

  .header_search {
    width: 8%;
    height: 100%;
    position: absolute;
    //right: 4.5rem;
    right: 0.5rem;
    z-index: 5;

    i {
     font-size: 20px;
    }
  }
}
</style>
